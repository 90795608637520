/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../../utils/runtypes';

// Sub service categories for skincare infusions.
export enum SkincareHydrationCategories {
    SkinBrightening = 'Skin Brightening',
    HydrationFluids = 'Hydration Fluids',
    VitaminInfusions = 'Vitamin Infusions',
    HydrationFluidsInfusionAddOns = 'Hydration Fluids Infusion Add Ons',
    IntraMuscularShots = 'Intra Muscular Shots',
    NadPlusInfusions = 'Nad + Infusions',
    VitaminInfusionPackages = 'Vitamin Infusion Packages',
    IronInfusionPackages = 'Iron Infusion Packages',
}
export const SkincareHydrationCategoriesSchema = runtypeFromEnum(SkincareHydrationCategories);

export enum SkinBrighteningServices {
    SkinBrightening = 'Skin Brightening',
}
export const SkinBrighteningServicesSchema = runtypeFromEnum(SkinBrighteningServices);

export enum HydrationInfusionServices {
    Infusion500MlHartmannOrSaline = "500 ML Hartmann's/Saline",
    Infusion1000MlHartmannOrSaline = "1000 ML Hartmann's/Saline",
}
export const HydrationInfusionServicesSchema = runtypeFromEnum(HydrationInfusionServices);

export enum HydrationVitaminInfusionsServices {
    VitaCBoost = 'VitaCBoost',
    VitaBBoost = 'VitaBBoost',
    VitaBlendCBFusion = 'Vita Blend C+B Fusion',
    AussieHydrate = 'Aussie Hydrate',
    AussieMixFusion = 'Aussie Mix Fusion',
    AussieInfusionCocktailMyerCocktailMix = 'Aussie Infusion Cocktail/Myer Cocktail Mix',
    AussieMixFusion500MGNADCombo = 'Aussie Mix Fusion + 500mg NAD+ Combo',
}
export const HydrationVitaminInfusionsServicesSchema = runtypeFromEnum(HydrationVitaminInfusionsServices);

export enum HydrationFluidsInfusionAddOns {
    VitaminC = 'Vitamin C',
    BComplex = 'B Complex',
    Zinc = 'Zinc',
    Magnesium = 'Magnesium',
    FolicAcid1MLShot = 'Folic Acid - 1ml Shot (Add On)',
    B1Thiamine1MLShot = 'B1 Thiamine - 1ml Shot (Add On)',
}
export const HydrationFluidsInfusionAddOnsSchema = runtypeFromEnum(HydrationFluidsInfusionAddOns);

export enum HydrationIntraMuscularShots {
    MethylcobalaminB12Shot = 'Methylcobalamin (B12) Shot',
    AussieBDose2mlShot = 'Aussie B-Dose 2ml Shot',
    AussieBDoseForte25mlShot = 'Aussie B-Dose Forte 2.5ml Shot',
    FolicAcid1mlShot = 'Folic Acid - 1ml Shot',
    B1Thiamine1mlShot = 'B1 Thiamine - 1ml Shot',
}
export const HydrationIntraMuscularShotsSchema = runtypeFromEnum(HydrationIntraMuscularShots);

export enum HydrationNADPlusInfusions {
    NAD500MG = '500 MG NAD',
    NAD3Pack500MG = '3 Pack 500 MG NAD',
    NAD5Pack500MG = '5 Pack 500 MG NAD',
}
export const HydrationNADPlusInfusionsSchema = runtypeFromEnum(HydrationNADPlusInfusions);

export enum HydrationVitaminInfusionPackages {
    NAD500MG = '500 ML Hartmanns/Saline 5 Pack',
    VitaCBoost5Pack = 'Vita C Boost 5 Pack',
    VitaBBoost5Pack = 'Vita B Boost 5 Pack',
    VitaBlendCBFusion5Pack = 'Vita Blend C+B Fusion 5 Pack',
    AussieMix5Pack = 'Aussie Mix 5 Pack',
    AussieInfusionCocktailMix5Pack = 'Aussie Infusion Cocktail Mix 5 Pack',
}
export const HydrationVitaminInfusionPackagesSchema = runtypeFromEnum(HydrationVitaminInfusionPackages);

export enum HydrationIronInfusionPackages {
    IronInfusion = 'Iron Infusion',
}
export const HydrationIronInfusionPackagesSchema = runtypeFromEnum(HydrationIronInfusionPackages);

export const HydrationTherapyServicesSchema = HydrationInfusionServicesSchema.Or(
    HydrationVitaminInfusionsServicesSchema,
)
    .Or(HydrationFluidsInfusionAddOnsSchema)
    .Or(HydrationIntraMuscularShotsSchema)
    .Or(HydrationNADPlusInfusionsSchema)
    .Or(SkinBrighteningServicesSchema)
    .Or(HydrationVitaminInfusionPackagesSchema)
    .Or(HydrationIronInfusionPackagesSchema);
export type HydrationTherapyServices = r.Static<typeof HydrationTherapyServicesSchema>;
